import Transformer from './Transformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} planning_schedule
 */
export default class PlanningScheduleTransformer extends Transformer {

    table = 'planning_schedule'

    async transform (schedule) {
        return {
        	'schedule_id'		: schedule.schedule_id,
        	'schedule_start'	: schedule.schedule_start,
        	'schedule_end'		: schedule.schedule_end,
        	'schedule_planning'	: schedule.schedule_planning,
        	'schedule_order'	: schedule.schedule_order
        }
    }
}
