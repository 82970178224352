import Transformer from './Transformer'
import HorseTransformer from './HorseTransformer'
import PlanningScheduleTransformer from './PlanningScheduleTransformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} planning_horse
 */
export default class PlanningHorseTransformer extends Transformer {

    table = 'planning_horse'

    fetch(col) {
        return col.with({
            'horse'         : 'planninghorse_stallion',
            'schedule'      : 'planninghorse_schedule',
        })
    }

    async transform (planning) {
        return {
        	'planninghorse_planning'	: planning.planninghorse_planning,
        	'planninghorse_stallion'	: await HorseTransformer.process(planning.horse, 'light'),
        	'planninghorse_schedule'	: await PlanningScheduleTransformer.process(planning.schedule)
        }
    }

    fetchLight(col) {
        return col.with({
            'horse'    : 'planninghorse_stallion',
        })
    }

    async transformLight (planning) {
        return await HorseTransformer.process(planning.horse, 'light')
    }
}
