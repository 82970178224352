import Transformer from './Transformer'
import HorseTransformer from './HorseTransformer'
import ContactTransformer from './ContactTransformer'
import TierTransformer from './TierTransformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} planning_horse
 */
export default class PlanningScheduleHorseTransformer extends Transformer {

    table = 'planning_schedule_horse'

    fetch(col) {
        return col.with({
            'stallion'		: 'schedulehorse_stallion',
            'mare'			: 'schedulehorse_mare',
            'contact'       : 'schedulehorse_contact',
            'tiers' 		: 'schedulehorse_tiers'
        })
    }

    async transform (schedule) {
        return {
        	'schedulehorse_id'          : schedule.schedulehorse_id,
        	'schedulehorse_stallion'	: await HorseTransformer.process(schedule.stallion, 'light'),
        	'schedulehorse_mare'		: schedule.mare ? await HorseTransformer.process(schedule.mare, 'lightWithPedigree') : null,
            'schedulehorse_contact'     : schedule.contact ? await ContactTransformer.process(schedule.contact, 'withPhone') : null,
        	'schedulehorse_tiers'		: schedule.tiers ? await TierTransformer.process(schedule.tiers, 'withPhone') : null,
        	'schedulehorse_date'		: schedule.schedulehorse_date,
            'schedulehorse_commentaire' : schedule.schedulehorse_commentaire
        }
    }
}
