import Transformer from './Transformer'
import PlanningScheduleTransformer from './PlanningScheduleTransformer'
import PlanningHorseTransformer from './PlanningHorseTransformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} planning
 */
export default class PlanningTransformer extends Transformer {

    table = 'planning'

    getSchedule(planning_id) {
		return this.db().t('planning_schedule')
        .then(table => {
            return table.where({schedule_planning: parseInt(planning_id)}).sortBy('schedule_order')
        })
        .then(async array => {
            return await PlanningScheduleTransformer.process(array)
        })
    }

    getHorse(planning_id) {
		return this.db().t('planning_horse')
        .then(table => {
            return table.where({planninghorse_planning: parseInt(planning_id)}).sortBy('planninghorse_order')
        })
		.then(async array => {
            return await PlanningHorseTransformer.process(array)
		})
    }

    async transform (planning) {
    	const schedule = await this.getSchedule(planning.planning_id)
    	const horse = await this.getHorse(planning.planning_id)

        return {
        	'planning_id'		: planning.planning_id,
        	'planning_season'	: planning.planning_season,
        	'planning_type'		: planning.planning_type,
            'planning_default'  : planning.planning_default,
            'planning_lieu'     : planning.planning_lieu,
        	'planning_schedule' : schedule,
        	'planning_horse' 	: horse
        }
    }

    async transformLight (planning) {
        return {
            'planning_id'       : planning.planning_id,
            'planning_season'   : planning.planning_season,
            'planning_type'     : planning.planning_type,
            'planning_default'  : planning.planning_default,
            'planning_lieu'     : planning.planning_lieu,
        }
    }
}
